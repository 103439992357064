// @ts-nocheck
import { useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify'; 
import { reactQueryKeys } from '../../common';
import { useGlobalStore } from '@bryzos/giss-ui-library';
const store:any = useGlobalStore.getState();
 
const getUser = () => {
  return Auth.currentAuthenticatedUser().then((res) => {
    store.setStartLoginProcess(true);
    return res;
  });
};

const useCognitoUser = (isUserLoggedIn) => {
  return useQuery([reactQueryKeys.cognitoUser], getUser, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!isUserLoggedIn,
    onError(err) {
      store.setIsAppReadyToUseInState(true);
      return err;
    },
  });
};

export default useCognitoUser;
