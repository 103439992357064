// @ts-nocheck
import clsx from 'clsx';
import {
    useState,
    useEffect,
    useRef
} from 'react';
import Header from '../header';
import styles from './buyerSetting.module.scss'
import { ReactComponent as DropdownIcon } from '../../assets/images/icon_Triangle.svg';
import { ReactComponent as DropdownIconSmall } from '../../assets/images/icon_Triangle1.svg';
import { ReactComponent as ErrorMessageIcon } from '../../assets/images/error-warning.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { ReactComponent as CheckReceived } from '../../assets/images/checkreceived.svg';
// import { ReactComponent as AddCertificateIcon } from '../../assets/images/add-certificate-icon.svg';
// import { ReactComponent as UploadCerti } from '../../assets/images/icon_Upload.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/tnc-close.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import { ReactComponent as AddCertiIcon } from '../../assets/images/AddLine.svg';
import { ReactComponent as DeleteCertiIcon } from '../../assets/images/Remove-line.svg';
import { useNavigate } from 'react-router';
import { fileType, prefixUrl, RecevingHoursFrom, RecevingHoursTo, routes, userRole, buyerSettingConst, commomKeys, defaultResaleCertificateLine } from '../../../common';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './buyerSchema';
import { Autocomplete, ClickAwayListener, Dialog, Fade, TextField, Tooltip } from '@mui/material';
// import { Popper,  Menu } from '@mui/material';
import axios from 'axios';
import { CustomMenu } from './CustomMenu';
import { formatCurrency, formatPhoneNumber, formatEIN, downloadFiles, formatToTwoDecimalPlaces, commonRaygunError, dispatchRaygunError, trueVaultRaygunError, formatPhoneNumberWithCountryCode, formatPhoneNumberRemovingCountryCode, formatCurrencyWithComma, removeCommaFromCurrency, navigatePage } from '../../helper';
import { BACHCreditTooltip, BCompanyAddressTooltip, BCompanyNameTooltip, BDeliverDateTooltip, BDeliverToTooltip, BEmailPhoneTooltip, BNet30TermsTooltip, BReceivingHoursTooltip, BResaleCertTooltip, BSendInvoicesTooltip, BShippingDocsTooltip, BYourNameTooltip, CompanyEntityTooltip, deleteCertificateLineTooltip, deleteCertificateTooltip } from '../../tooltip';
import { v4 as uuidv4 } from 'uuid';
import TrueVaultClient from 'truevault';
import Loader from '../../Loader/Loader';
import DialogPopup from '../../component/DialogPopup/DialogPopup';
import { CommonTooltip } from '../../component/Tooltip/tooltip';
import { useImmer } from 'use-immer';
import useGetCompanyLists from '../../hooks/useGetCompanyLists';
import { useGlobalStore, getSocketConnection, getChannelWindow, emojiRemoverRegex } from '@bryzos/giss-ui-library';
import ChangePassword from 'src/renderer/component/changePassword/changePassword';

const MenuPropsTop = {
    classes: {
        paper: styles.RecevingTimepaper,
    },
    anchorOrigin: {
        vertical: -31,
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
}
const MenuPropsBottom = {
    classes: {
        paper: styles.RecevingTimepaper1,
    },
    anchorOrigin: {
        vertical: 27,
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
}

const ExpirationMenuPropsTop = {
    classes: {
        paper: clsx(styles.Expirationpaper, styles.RecevingTimepaper)
    },
    anchorOrigin: {
        vertical: -31,
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
}
const ExpirationMenuPropsBottom = {
    classes: {
        paper: clsx(styles.Expirationpaper1, styles.RecevingTimepaper1)
    },
    anchorOrigin: {
        vertical: 27,
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
}


const weeks = [
    { name: 'Mon', value: 'monday', defaultFrom: '11', defaultTo: '15' },
    { name: 'Tue', value: 'tuesday', defaultFrom: '11', defaultTo: '15' },
    { name: 'Wed', value: 'wednesday', defaultFrom: '11', defaultTo: '15' },
    { name: 'Thu', value: 'thusday', defaultFrom: '11', defaultTo: '15' },
    { name: 'Fri', value: 'friday', defaultFrom: '11', defaultTo: '15' },
    { name: 'Sat', value: 'saturday', defaultFrom: '11', defaultTo: '15' },
    { name: 'Sun', value: 'sunday', defaultFrom: '11', defaultTo: '15' }
]
function BuyerSetting(props) {
    const {setShowLoader, backNavigation, userData, isImpersonatedUserLoggedIn, resetCommonStore, originalLoggedInUserData} = useGlobalStore();
    const navigate = useNavigate();
    const [States, setStates] = useState([]);
    const [DeliveryDates, setDeliveryDates] = useState([]);
    const [ResaleExpiration, setResaleExpiration] = useState([]);
    const [net30Default, setNet30Default] = useState([]);
    const [achDefault, setAchDefault] = useState([]);
    const [achTruevaultId, setAchTruevaultId] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [errorKeys, setErrorKeys] = useState([]);
    const [signedUrl1, setSignedUrl1] = useState('');
    const [UploadCertName1, setUploadCertName1] = useState('');
    const [signedUrl2, setSignedUrl2] = useState('');
    const [UploadCertName2, setUploadCertName2] = useState('');
    const [CertStatus1, setCertStatus1] = useState(null);
    const [CertStatus2, setCertStatus2] = useState(null);
    const [CertId1, setCertId1] = useState(null);
    const [CertId2, setCertId2] = useState(null);
    const [isApproved, setIsApproved] = useState('');
    const [achApproved, setAchApproved] = useState('');
    const [creditStatus, setCreditStatus] = useState('');
    const [OpenSubmitApp, setOpenSubmitApp] = useState(false);
    const [SuccessAppSubmit, setSuccessAppSubmit] = useState(false);
    const [RequestcreditLineAmount, setRequestcreditLineAmount] = useState(false);
    const [net30ApplyStatus, setNet30ApplyStatus] = useState(false);
    const [backToHome, setBackToHome] = useState(false);
    // const [isDataLoad, setisDataLoad] = useState(true);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [apiFailureDialog, setApiFailureDialog] = useState(false);
    const [editModeDesiredCreditLine, setEditModeDesiredCreditLine] = useState(true);
    const { register, handleSubmit, getValues, clearErrors, setValue, watch, setError, control, trigger, formState: { errors, dirtyFields, isDirty, isValid } } = useForm({
        defaultValues: {companyName: null},
        resolver: yupResolver(schema),
        criteriaMode: "all"
    });

    const [isSaveDisable, setIsSaveDisable] = useState(true);
    const [uploadCert1Progress, setUploadCert1Progress] = useState(null);
    const [uploadCertProgress, setUploadCertProgress] = useState(null);
    const [validationInProgress, setValidationInProgress] = useState(true);
    //Dialog Popup 
    const [showDialogUploadCertPopup, setShowDialogUploadCertPopup] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogBtnTitle, setDialogBtnTitle] = useState('');
    const [dialogType, setDialogType] = useState('');
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [openChangePassPopup, setOpenChangePassPopup] = useState(false);
    //
    const [payloadData, setPayloadData] = useState('');
    const [companyNameValue, setCompanyNameValue] = useImmer(null);
    const [companyNameInput, setCompanyNameInput] = useState("");
    const [yourCompanyValue, setYourCompanyValue] = useImmer(null);
    const [yourCompanyInput, setYourCompanyInput] = useState("");
    const [disableYourCompany, setDisableYourCompany] = useState(true);
    const [isCompanyNameExists, setIsCompanyNameExists] = useState(true);
    const [yourCompanyList, setYourCompanyList] = useState([]);
    const socket = getSocketConnection();

    const { data: companyListsData, isLoading: isCompanyListsDataLoading, } = useGetCompanyLists();
    const channelWindow = getChannelWindow() ;

    const singleErrorKey = errorKeys.find(x => errors[x])

    useEffect(()=>{
        console.log("isDIrty", isDirty)
        if(isDirty)
        setIsSaveDisable(false);
    },[isDirty])

    useEffect(() => {
        if (userData.data?.id) {
            setShowLoader(true);
            const referenceData = userData.referenceData;
            referenceData.ref_pgpm_mapping.map((paymentMethod) => {
                if (paymentMethod.user_type === userRole.buyerUser.toLowerCase() && paymentMethod.payment_gateway === 'BALANCE') {
                    setNet30Default(paymentMethod.payment_method);
                }
                if (paymentMethod.user_type === userRole.buyerUser.toLowerCase() && paymentMethod.payment_gateway === 'DEFAULT') {
                    setAchDefault(paymentMethod.payment_method);
                }
            })
            setDeliveryDates(referenceData.ref_delivery_date);
            setStates(referenceData.ref_states);
            let expiresData = [];
            referenceData.ref_resale_cert_expiration.map((expiration) => {
                const expireData = {
                    title: expiration.expiration_display_string,
                    value: expiration.expiration_value
                }
                return expiresData = [...expiresData, expireData];
            })


            setResaleExpiration(expiresData);

            if(referenceData.ref_account_routing_number[0]){
                if(referenceData.ref_account_routing_number[0]?.account_number){
                    setValue('refAccountNo', referenceData.ref_account_routing_number[0]?.account_number);
                }
                if(referenceData.ref_account_routing_number[0]?.routing_number){
                    setValue('refRoutingNo', referenceData.ref_account_routing_number[0]?.routing_number);
                }
            }
            axios.get(import.meta.env.VITE_API_SERVICE + '/user/buyingPreference', {
                headers: {
                    UserId: userData.data.id
                }
            })
                .then(response => {
                    const buyerSettings = response.data.data;
                    if (buyerSettings) {
                        setValue('companyName', buyerSettings.company_name);
                        setValue('yourCompany', buyerSettings.client_company);
                        setYourCompanyInput(buyerSettings.client_company ?? "")
                        setIsCompanyNameExists(buyerSettings.company_name != null);
                        setDisableYourCompany(buyerSettings.company_name === null)
                        setValue('companyAddressLine', buyerSettings.company_address_line1);
                        setValue('companyCity', buyerSettings.company_address_city);
                        setValue('companyState', buyerSettings.company_address_state_id);
                        setValue('companyZipCode', buyerSettings.company_address_zip);
                        setValue('firstName', buyerSettings.first_name);
                        setValue('lastName', buyerSettings.last_name);
                        setValue('emailAddress', buyerSettings.email_id);
                        setValue('phoneNo', formatPhoneNumberRemovingCountryCode(buyerSettings.phone));
                        setValue('deliverToAddress', buyerSettings.delivery_address_line1);
                        setValue('deliverToCity', buyerSettings.delivery_address_city);
                        setValue('deliverToState', buyerSettings.delivery_address_state_id);
                        setValue('deliverZipCode', buyerSettings.delivery_address_zip);
                        setValue('deliveryDate', buyerSettings.delivery_days_add_value);
                        setValue('sendInvoicesTo', buyerSettings.send_invoices_to);
                        setValue('shippingDocsTo', buyerSettings.shipping_docs_to);
                        setValue('shippingDocsTo', buyerSettings.shipping_docs_to);
                        setSelectedPaymentMethod(buyerSettings.default_payment_method)
                        if (buyerSettings.user_delivery_receiving_availability_details) {
                            const weeks = buyerSettings.user_delivery_receiving_availability_details.map( day => {
                                day.receivingHrsFrom = [...RecevingHoursFrom]
                                day.receivingHrsTo = [...RecevingHoursTo]
                                return day;
                            });
                            setValue('dates',weeks);
                        }
                        if (buyerSettings.resale_certificate) {
                            let resaleCertificateList = [ defaultResaleCertificateLine, defaultResaleCertificateLine];
                            const resaleCertificate = buyerSettings.resale_certificate;
                            if(resaleCertificate.length !== 0){
                                resaleCertificate.forEach((data,i)=>{
                                    data.uploadCertProgress = false
                                    resaleCertificateList[i] = data;
                                })
                            }
                            setValue("resaleCertificateList", resaleCertificateList)
                        }

                        if (buyerSettings.ach_credit) {
                            setValue('bankName', buyerSettings.ach_credit.bank_name);
                            setValue('routingNo', buyerSettings.ach_credit.routing_number);
                            setValue('accountNo', buyerSettings.ach_credit.account_number);
                            setAchTruevaultId(buyerSettings.ach_credit.truvault_document_id);
                            setAchApproved(buyerSettings.ach_credit.is_approved)

                        }
                        if (buyerSettings.bnpl_settings) {
                            let requestedCredit = formatCurrency(parseFloat(buyerSettings.bnpl_settings.requested_credit_limit))

                            if (buyerSettings.bnpl_settings.is_approved !== 0) {
                                setValue('bnplAvailable', true);
                                setValue('dnBNumber', buyerSettings.bnpl_settings.duns);
                                setValue('einNumber', buyerSettings.bnpl_settings.ein_number);
                                setValue('creditLine', requestedCredit);
                            }

                            // getValues('creditLine')
                            setValue('requestedCreditLimit', requestedCredit);
                            setNet30ApplyStatus(true);

                            let balanceCredit = formatCurrency(buyerSettings.bnpl_settings.balance_credit_limit);
                            setValue('balanceCreditLimit', balanceCredit);
                            if (buyerSettings.bnpl_settings.requested_increase_credit) {
                                let requestedIncreaseCredit = formatCurrency(parseFloat(buyerSettings.bnpl_settings.requested_increase_credit))
                                setValue('requestedIncreaseCredit', requestedIncreaseCredit)
                            }
                            let availableBalanceCredit = formatCurrency(parseFloat(buyerSettings.bnpl_settings.balance_available_credit_limit))
                            setValue('availableBalance', availableBalanceCredit)
                            let outstandingValue = '0';
                            if (buyerSettings.bnpl_settings.outstanding_amount === null) {
                                outstandingValue = '0';
                            } else {
                                outstandingValue = buyerSettings.bnpl_settings.outstanding_amount;
                            }
                            let outstandingBalanceAmount = formatCurrency(parseFloat(outstandingValue))
                            setValue('outstandingAmount', outstandingBalanceAmount)
                            setIsApproved(buyerSettings.bnpl_settings.is_approved);
                            setCreditStatus(buyerSettings.bnpl_settings.credit_status);
                        } else {
                            setIsApproved('');
                            setCreditStatus('');
                        }

                        setErrorKeys([])
                        clearErrors();
                    }
                    // setisDataLoad(false)
                    setShowLoader(false);

                })
                .catch(error => {
                    console.error(error);
                    // setisDataLoad(false);
                    setShowLoader(false);
                    setApiFailureDialog(true)
                }
            );
        }

    }, [userData?.data?.id]);

    useEffect(() => {
        handleStateZipValidation('companyZipCode','companyState')
    },[watch('companyZipCode'),watch('companyState')])

    useEffect(() => {
        handleStateZipValidation('deliverZipCode','deliverToState')
    },[watch('deliverZipCode'),watch('deliverToState')])

    useEffect(() => {
        if(watch("companyName") !== null){
            const companyData = companyListsData?.find((companyData) => companyData.company_name === watch("companyName"))
            setYourCompanyList(companyData?.client_company ?? [])
        }
    },[watch("companyName")])

    const getTruevaultData = async (companyName, userData, bankName, routingNo, accountNo, paymentMethod, einNumber, dnBNumber) => {
        try {
            const res = await axios.get(import.meta.env.VITE_API_PHP_SERVICE + '/api/v1/widget/truevault/get-access-token');
            const accessToken = res.data.data.access_token;
            let buyerPaymentData = {};
            if (paymentMethod === achDefault) {
                buyerPaymentData = {
                    "document": {
                        "company_name": companyName,
                        "user_id": userData,
                        "bank_name": bankName,
                        "routing_number": routingNo,
                        "account_number": accountNo,
                        "payment_method": paymentMethod
                    }
                }
            }
            else if (paymentMethod === net30Default) {
                buyerPaymentData = {
                    "document": {
                        "company_name": companyName,
                        "user_id": userData,
                        "dnb_number": dnBNumber,
                        "net_30_ein": einNumber,
                        "payment_method": paymentMethod
                    }
                }
            }

            const client = new TrueVaultClient({ accessToken });

            try {
                const response = await client.createDocument(import.meta.env.VITE_TRUE_VAULT_ID_BUYER_VAULT_ID, null, buyerPaymentData);
                const documentIdFromTruevault = response.id;
                if(!documentIdFromTruevault){
                    dispatchRaygunError(new Error('TrueVault error: TruevoltObject = ' + JSON.stringify(response)), [trueVaultRaygunError]);
                }
                return documentIdFromTruevault;

            } catch (error) {
                console.error("Error creating document:", error);
                dispatchRaygunError(error, [trueVaultRaygunError]);
            }

        } catch (err) {
            console.error(err)
        }

    }
    function BackToHomeDialog() {
        return (
            <>
                <Dialog
                    open={backToHome}
                    onClose={(event) => setBackToHome(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: clsx(styles.SubmitApp, styles.backToHomePopup),
                        paper: styles.dialogContent,
                    }}
                >
                    <button className={styles.closeIcon} onClick={(event) => setBackToHome(false)}><CloseIcon /></button>
                    <p className={styles.bactToHomeTitle}>You have unsaved changes</p>
                    <button className={styles.btnOfPopup} onClick={(event) => { handleSubmit(onSubmit)(); setBackToHome(false) }} >Save Changes</button>
                    <button className={styles.btnOfPopup} onClick={() => navigate(backNavigation)}>Don’t Save</button>
                    <button className={styles.btnOfPopup} onClick={(event) => setBackToHome(false)}>Go Back to Settings</button>
                </Dialog>
            </>
        );
    }
    const onSubmit = (data, callNet30Terms) => {
        const payload = {
            "data": {
                "company_name": data.companyName,
                "client_company": data.yourCompany,
                "address": {
                    "line1": data.companyAddressLine,
                    "city": data.companyCity,
                    "state_id": data.companyState,
                    "zip": data.companyZipCode
                },
                "first_name": data.firstName,
                "last_name": data.lastName,
                "email_id": data.emailAddress,
                "phone": formatPhoneNumberWithCountryCode(data.phoneNo),
                "delivery_address": {
                    "line1": data.deliverToAddress,
                    "city": data.deliverToCity,
                    "state_id": data.deliverToState,
                    "zip": data.deliverZipCode
                },
                "delivery_address_zip": data.deliverZipCode,
                "delivery_days_add_value": data.deliveryDate,
                "send_invoices_to": data.sendInvoicesTo,
                "shipping_docs_to": data.shippingDocsTo,
                "default_payment_method": null,
            }
        }
        let uploadCert = [];
        if(data.resaleCertificateList?.length) {
        uploadCert = data.resaleCertificateList.filter((resaleCertificate: any) => {
            if (resaleCertificate.uploadCertProgress !== null) {
                return resaleCertificate
            }
        })
        }

        if (uploadCert.length !== 0) {
            payload.data.resale_certificate = uploadCert;
        }
        const dates = Object.values(data.dates);
        let selectedDates = [];
        dates.map((date) => {
            if (date.selected === true) {
                const selectedDate = {
                    day: date.name,
                    from: date.from.toString(),
                    to: date.to.toString()
                }
                return selectedDates = [...selectedDates, selectedDate];
            }
        })
        payload.data.user_delivery_receiving_availability_details = getValues('dates').map( day => {
            const dayCopy = {...day};
            dayCopy.receivingHrsFrom = undefined;
            dayCopy.receivingHrsTo = undefined;
            return dayCopy;
        });

        if (selectedPaymentMethod === achDefault) {
            if (data.bankName !== '' && data.routingNo !== '' && data.accountNo !== '') {
                if (dirtyFields.bankName || dirtyFields.routingNo || dirtyFields.accountNo) {
                    if (isNaN(data.routingNo) || isNaN(data.accountNo)) {
                        const key = isNaN(data.routingNo) ? 'routingNo' : 'accountNo'
                        setError(key, 'field is not vaild', { shouldFocus: true });
                        return
                    }
                    getTruevaultData(data.companyName, userData.data.id, data.bankName, data.routingNo, data.accountNo, achDefault).then(referenceDocumentId => {
                        const achCredits = {};
                        const convertedRoutingNo = data.routingNo.slice(-2).padStart(data.routingNo.length, 'x');
                        const convertedAccountNO = data.accountNo.slice(-2).padStart(data.accountNo.length, 'x');
                        achCredits.bank_name = data.bankName;
                        achCredits.routing_number = convertedRoutingNo;
                        achCredits.account_number = convertedAccountNO;
                        achCredits.truvault_document_id = referenceDocumentId;
                        payload.data.default_payment_method = achDefault;
                        payload.data.ach_credit = achCredits;
                        submitData(payload, callNet30Terms);
                    })
                } else {
                    const achCredits = {};
                    achCredits.bank_name = data.bankName;
                    achCredits.routing_number = data.routingNo;
                    achCredits.account_number = data.accountNo;
                    achCredits.truvault_document_id = achTruevaultId;
                    payload.data.default_payment_method = achDefault;
                    payload.data.ach_credit = achCredits;
                    submitData(payload, callNet30Terms);

                }
            } else {

                const achCreditFields = ['bankName', 'routingNo', 'accountNo'];
                for (let trial in achCreditFields) {
                    if (getValues(achCreditFields[trial]) === '') {
                        setError(achCreditFields[trial], 'Field is empty');
                    }
                }

            }
        } else if (selectedPaymentMethod === net30Default && net30ApplyStatus) {
            if (data.creditLine !== '' && data.dnBNumber !== '') {
                payload.data.default_payment_method = net30Default;
                submitData(payload, callNet30Terms)
            } else {
                const net30Fields = ['creditLine', 'dnBNumber'];
                for (let trial in net30Fields) {
                    if (getValues(net30Fields[trial]) === '') {
                        setError(net30Fields[trial], 'Field is empty');
                    }
                }
            }
        }
        else {
            submitData(payload, callNet30Terms)

        }


    }

    const submitData = async (payload, callNet30Terms) => {
        setIsSaveDisable(true);
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/buyingPreference', payload, {
            headers: {
                UserId: userData.data.id
            }
        })
            .then(response => {
                if(callNet30Terms) {
                    applyNetData();
                    setShowLoader(true);
                }
                else
                navigate(routes.homePage)
                setIsSaveDisable(false);

            })
            .catch(error => {
                console.error(error);
                // setisDataLoad(false);
                setShowLoader(false);
                setApiFailureDialog(true)
                setIsSaveDisable(false);
            }
            );

    }

    const uploadCertFile = async (event, i) => {
        const file = event.target.files[0];
        if (event.target.files.length !== 0) {
            // setUploadCertProgress(true)
            setValue(`resaleCertificateList.${i}.uploadCertProgress`, true)
            setValue(`resaleCertificateList.${i}.state_id`, '');
            setValue(`resaleCertificateList.${i}.expiration_date`, '');
            setValue(`resaleCertificateList.${i}.status`, null);
            setValue(`resaleCertificateList.${i}.file_name`, file.name);
            // setCertStatus2(null)
            // setUploadCertName2(file.name);
            let index = file.name.length - 1;
            for (; index >= 0; index--) {
                if (file.name.charAt(index) === '.') {
                    break;
                }
            }
            const ext = file.name.substring(index + 1, file.name.length);

            const objectKey = import.meta.env.VITE_ENVIRONMENT + '/' + userData.data.id + '/' + prefixUrl.resaleCertPrefix + '-' + uuidv4() + '.' + ext;

            const payload = {
                data: {
                    "bucket_name": import.meta.env.VITE_S3_UPLOAD_SETTINGS_RESALE_CERT_BUCKET_NAME,
                    "object_key": objectKey,
                    "expire_time": 300

                }
            }
            let setCertUrl = 'https://' + payload.data.bucket_name + ".s3.amazonaws.com/" + payload.data.object_key;
            setValue(`resaleCertificateList.${i}.resaleCertFile`, '')
            axios.post(import.meta.env.VITE_API_SERVICE + '/user/get_signed_url', payload)
                .then(response => {
                    const signedUrl = response.data.data;
                    axios.put(signedUrl, file)
                        .then(response => {
                            if (response.status === 200) {
                                setValue(`resaleCertificateList.${i}.uploadCertProgress`, false)
                                setValue(`resaleCertificateList.${i}.cerificate_url_s3`, setCertUrl)
                                // setUploadCertProgress(false);
                                setShowDialogUploadCertPopup(true);
                                setDialogTitle(commomKeys.uploadSuccessful)
                                setDialogContent(buyerSettingConst.uploadCertDialogContent)
                                setDialogBtnTitle(commomKeys.successBtnTitle)
                                setDialogType(commomKeys.actionStatus.success)
                                // setSignedUrl2(setCertUrl)

                            }
                        })
                        .catch(error => {
                            console.error(error);
                            // setisDataLoad(false);
                            setShowLoader(false);
                            let uploadProgress = null;
                            if(getValues(`resaleCertificateList.${i}.cerificate_url_s3`)){
                                uploadProgress = false;
                            }
                            setValue(`resaleCertificateList.${i}.uploadCertProgress`, uploadProgress)
                            setApiFailureDialog(true)
                        }
                        );
                })
                .catch(error => {
                    console.error(error);
                    // setisDataLoad(false);
                    setShowLoader(false);
                    let uploadProgress = null;
                    if(getValues(`resaleCertificateList.${i}.cerificate_url_s3`)){
                        uploadProgress = false;
                    }
                    setValue(`resaleCertificateList.${i}.uploadCertProgress`, uploadProgress)
                    setApiFailureDialog(true)
                }
                );

        }
    }



    const submitNetData = (data) => {
        if (!getValues('dnBNumber') || !getValues('creditLine') || !getValues('einNumber')) {
            const net30Fields = ['creditLine', 'dnBNumber', 'einNumber'];
            for (let trial in net30Fields) {
                if (getValues(net30Fields[trial]) === '') {
                    setError(net30Fields[trial], { message: `Net 30 Terms is not valid` });
                }
            }

        } else {
            if (!/^\d{9}$/.test(getValues('dnBNumber'))) {
                setError('dnBNumber', { message: 'D&B Number is not valid' }, { shouldFocus: true });
                return
            }
            if (!/^\d{2}-\d{7}$/.test(getValues('einNumber'))) {
                setError('einNumber', { message: 'EIN Number is not valid' }, { shouldFocus: true });
                return
            }
            if (+getValues('creditLine') <= 0) {
                setError('creditLine', { message: `Net 30 Terms is not valid` });
                return;
            }
            if (getValues('creditLine') > buyerSettingConst.buyerCreditLineLimit) {
                setError('creditLine', { message: buyerSettingConst.creditLimitErrorMessage }, { shouldFocus: true });
                return
            }
            clearErrors(['dnBNumber', 'creditLine', 'einNumber']);
            setErrorKeys([]);
            onSubmit(data, true);
        }

    }

    const applyNetData = () => {
        setOpenSubmitApp(false);
        getTruevaultData(getValues('companyName'), userData.data.id, null, null, null, net30Default, getValues('einNumber'), getValues('dnBNumber')).then(documentIdFromTruevault => {
            const payload = {
                "data": {
                    "company_name": getValues('companyName'),
                    "company_address": {
                        "line1": getValues('companyAddressLine'),
                        "city": getValues('companyCity'),
                        "state_id": getValues('companyState'),
                        "zip": getValues('companyZipCode')
                    },
                    "first_name": getValues('firstName'),
                    "last_name": getValues('lastName'),
                    "email_id": getValues('emailAddress'),
                    "phone": formatPhoneNumberWithCountryCode( getValues('phoneNo') ),
                    "pay_later_on_bryzos": {
                        "ein_number": getValues('einNumber').slice(-2).padStart(getValues('einNumber').length, 'x'),
                        "duns_number": getValues('dnBNumber'),
                        "agreed_terms": true,
                        "desired_credit_limit": getValues('creditLine'),
                        "pgpm_mapping_id": 4,
                        "reference_document_id": documentIdFromTruevault ? documentIdFromTruevault : ''
                    }
                }
            };

            axios.post(import.meta.env.VITE_API_PHP_SERVICE + '/api/v1/widget/save-buy-now-pay-later-data', payload, {
                headers: {
                    UserId: userData.data.id
                }
            })
            .then(res => {
                if (res.status === 200) {
                    setSuccessAppSubmit(true)
                    // setisDataLoad(false)
                    setShowLoader(false);
                    setNet30ApplyStatus(true);
                }
            })
            .catch(err => {
                console.error(err);
                // setisDataLoad(false);
                setShowLoader(false);
                setApiFailureDialog(true);
            });
        })

    }

    function SubmitApplicationSuccessDialog() {

        return (
            <>

                <Dialog
                    open={SuccessAppSubmit}
                    onClose={(event) => setSuccessAppSubmit(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: clsx(styles.SubmitApp, styles.successPopup),
                        paper: styles.dialogContent,
                    }}
                >
                    <button className={styles.closeIcon} onClick={(event) => { setSuccessAppSubmit(false); setOpenSubmitApp(false); setIsApproved(null); }}><CloseIcon /></button>
                    <p className={styles.successPopupTitle}>SUCCESS</p>
                    <p>Your application has been submitted<br /> and is currently being reviewed.</p>
                    <p>Typically, we will have an answer to you in<br /> minutes. Sometimes it takes longer.</p>
                    <p>We will reach out if additional<br /> information is needed.</p>
                    <p>We will notify you, via email and<br /> desktop notification, once a credit<br /> decision has been made.</p>


                    <div>
                        <button className={styles.submitBtn} onClick={(event) => { setSuccessAppSubmit(false); setOpenSubmitApp(false); setIsApproved(null); }}>Got it</button>
                    </div>
                </Dialog>
            </>
        );
    }



    const showErrorKeyVal = (fieldNames) => {
        setErrorKeys(fieldNames)
    }

    const disableRequestCreditLine = () => {
        return (+getValues("requestCreditLine")) < 1;
    }

    const submitRequestCreditLine = () => {
        if (getValues('requestCreditLine') > buyerSettingConst.buyerCreditLineLimit) {
            setError('requestCreditLine', { message: buyerSettingConst.creditLimitErrorMessage }, { shouldFocus: true });
            showErrorKeyVal(['requestCreditLine'])
            return;
        }
        clearErrors();
        if (getValues('requestCreditLine') > 0) {
            const payload = {
                "data": {
                    "request_increase_credit": getValues('requestCreditLine')
                }
            }
            axios.post(import.meta.env.VITE_API_SERVICE + '/user/increaseCreditLimitRequest', payload)
                .then(res => {
                    setRequestcreditLineAmount(false)
                    setCreditStatus('Pending Increase')
                    setValue('requestedIncreaseCredit', formatCurrency(parseFloat(getValues('requestCreditLine'))))
                    setValue('requestCreditLine', '')
                })
                .catch(err => {
                    console.error(err);
                    // setisDataLoad(false);
                    setShowLoader(false);
                    setApiFailureDialog(true)
                }
                );
        } else {

            setError('requestCreditLine', 'field is not vaild', { shouldFocus: true });
            setError('type', 'error checking')
        }
    }

    const handleCreditReauqestCancel = () => {
        axios.post(import.meta.env.VITE_API_SERVICE + `/user/cancelIncreaseCreditLimitRequest`)
            .then(res => {
                setCreditStatus(null);
            })
            .catch(err => {
                console.error(err);
                // setisDataLoad(false);
                setShowLoader(false);
                setApiFailureDialog(true)
            });
    }



    const handlePaymentMethodChange = (method) => {
        if (selectedPaymentMethod === method) {
            // Deselect the selected method
            setSelectedPaymentMethod(null);
            let fields = [];
            if (method === achDefault) {
                fields = ['bankName', 'routingNo', 'accountNo'];
            } else if (method === net30Default) {
                fields = ['dnBNumber', 'einNumber', 'creditLine'];

            }
            for (let trial in fields) {
                clearErrors(fields[trial]);
            }
            setErrorKeys([]);
        } else {
            setSelectedPaymentMethod(method);
            if(method === achDefault){
                setValue('net30CheckBox', false);
                const fields = ['dnBNumber', 'einNumber', 'creditLine'];
                for (let trial in fields) {
                    clearErrors(fields[trial]);
                }
                setErrorKeys([]);
            }
        }
    };

    const handlePhoneNoChange = (event) => {
        const { value } = event.target;
        setValue('phoneNo', formatPhoneNumber(value));
    };
    const handleEinNoChange = (event) => {
        const { value } = event.target;
        setValue('einNumber', formatEIN(value));
    };

    const handleEmailChange = (event) => {
        event.target.value = event.target.value.replace(emojiRemoverRegex, '');
        register("emailAddress").onChange(event)
        const email = event?.target?.value;
        if (email) {
            setValue('emailAddress', email.toLocaleLowerCase());
        }
    };
    
    // const handleDnbNoChange = (event) => {
    //     console.log(event.target.value, 'heyyy')
    //     const { value } = event.target;
    //     const dnb = value.replace(/\D/g, '');
    //     setValue('dnBNumber', dnb);
    // };

    const [isRoutingMasked, setRoutingMasked] = useState(true);
    const routingNumber = getValues('routingNo')
    const handleMouseEnterRout = () => {
        setRoutingMasked(false);
    };
    const handleMouseLeaveRout = () => {
        setRoutingMasked(true);
    };

    const maskedRoutingNo = routingNumber?.slice(-2).padStart(routingNumber.length, 'x');


    const [isAccountMasked, setAccountMasked] = useState(true);
    const accountNumber = getValues('accountNo')

    const handleMouseEnter = () => {
        setAccountMasked(false);
    };

    const handleMouseLeave = () => {
        setAccountMasked(true);
    };

    const maskedAccountNo = accountNumber?.slice(-2).padStart(accountNumber.length, 'x');


    const downloadReports = (fileUrl, fileName, fileType) => {
        const showError = downloadFiles(fileUrl, fileName, fileType)
        showError.then(res => {
            if (res) {
                setOpenErrorDialog(false);
            } else {
                setOpenErrorDialog(true);
            }
        })

    }


    const desiredCreditLineEditModeHandler = (editMode) => {
        if (editMode) {
            setEditModeDesiredCreditLine(editMode);
            const t = setImmediate(() => {
                clearTimeout(t);
                const input = document.getElementById('desired-credit-line');
                if (input) {
                    input.focus();
                }
            }, 100);

        } else if (+getValues('creditLine')) {
            setEditModeDesiredCreditLine(editMode);
        }
    }
    const requestCreditLineChangeHandler = (e, fieldName) => {
        let value = e.target.value;
        if(!isNaN(removeCommaFromCurrency(value))) {
            setValue(fieldName, removeCommaFromCurrency(value));
        }
    }

    const changeReceivingHrs = (dateIndex, isReceivingHrsFrom, dropdownValue)=>{
        setValue(`dates.${dateIndex}.is_user_available`, true);
        const receivingHrsOption = [];
        let currentDropdown = `dates.${dateIndex}.to`;
        let adjacentDropdown = `dates.${dateIndex}.from`;
        let adjDropDownOptionsCopy = RecevingHoursFrom;
        let dropDownOptionsToBeDisabled = `dates.${dateIndex}.receivingHrsFrom`;
        let onChangingCancelAdjDropDownValue = RecevingHoursFrom[0].value;
        if(isReceivingHrsFrom){
            currentDropdown = `dates.${dateIndex}.from`;
            adjacentDropdown = `dates.${dateIndex}.to`;
            adjDropDownOptionsCopy = RecevingHoursTo;
            onChangingCancelAdjDropDownValue = RecevingHoursTo[RecevingHoursTo.length-2].value;
            dropDownOptionsToBeDisabled = `dates.${dateIndex}.receivingHrsTo`;
        }
        //setting value of user changed dropdown
        setValue(currentDropdown, dropdownValue.toString());
        //Changing (from and to) to closed check
        if(dropdownValue === 'closed'){
            setValue(adjacentDropdown, dropdownValue.toString());
            setValue( `dates.${dateIndex}.is_user_available`, 0);
        }
        //if both were closed and one of the value changes then autofill the other dropdown
        else if(watch(adjacentDropdown) === 'closed'){
            setValue( `dates.${dateIndex}.is_user_available`, 1);
            setValue(adjacentDropdown, onChangingCancelAdjDropDownValue.toString());
        }
        //disabling options from dropdown
        adjDropDownOptionsCopy.forEach(timeOption => {
            const time = {...timeOption};
            if(dropdownValue !== 'closed' && ((!isReceivingHrsFrom && time.value >= dropdownValue) || (isReceivingHrsFrom && time.value <= dropdownValue)))time.disabled = true;
            receivingHrsOption.push(time);
        })
        setValue(dropDownOptionsToBeDisabled, receivingHrsOption);
    }

    const handleStateZipValidation = async (zipCode, stateCode) => {
      if (getValues(zipCode)?.length > 4 && getValues(stateCode)) {
        setValidationInProgress(false)
        const payload = {
          data: {
            state_id: getValues(stateCode),
            zip_code: parseInt(getValues(zipCode)),
          },
        };
        const checkStateZipResponse = await axios.post(
          import.meta.env.VITE_API_SERVICE + "/user/checkStateZip",
          payload
        );
        if (checkStateZipResponse.data.data === true) {
          clearErrors([stateCode, zipCode]);
        } else {
          setError(stateCode, { message: "The zip code and state code do not match" }, { shouldFocus: true });
          setError(zipCode, { message: "The zip code and state code do not match" }, { shouldFocus: true });
        }
        setValidationInProgress(true)
      }
    };

    const handleFormSubmit = () => {
        if(Object.keys(errors).length === 0 && validationInProgress){
            handleSubmit(onSubmit)();
        }
    }
    const resaleCertFile1Ref = useRef();
    const resaleCertFileRef = useRef();
    const resaleCert1EditHandler = () => {
        resaleCertFile1Ref.current.click();
    }

    const resaleCertEditHandler = () => {
        resaleCertFileRef.current.click();
    }


    // Delete Certificate Popup
    const openDeletePopup = (cert_id, index) =>{
        setPayloadData({cert_id,index}); 
        setOpenDeleteConfirmation(true)
    }

    
    const changePassPopup = () => {
        setOpenChangePassPopup(true);
      }
    

    const deleteCerti = () => {
        const payload = {
            data: {
                cert_id: payloadData.cert_id,
            },
        };

        setOpenDeleteConfirmation(false)
        axios.post(import.meta.env.VITE_API_SERVICE + `/user/deleteResaleCert`,  payload )
            .then(response => {
                if (response.data.data.error_message){
                    setShowDialogUploadCertPopup(true)
                    setDialogType();
                    setDialogTitle();
                    setDialogContent(response.data.data.error_message)
                    setDialogBtnTitle(commomKeys.errorBtnTitle)
                }
                else{
                    const certId = getValues(`resaleCertificateList.${payloadData.index}.id`)
                    if(payloadData.cert_id === certId){
                        deleteResaleCertificateLine(payloadData.index);
                    }
                }
               
            })
            .catch(error => {
                console.error('Error deleting file:', error);
            });
    };

    const handleAddNewLine = () => {
        const resaleCertList = getValues("resaleCertificateList")
        setValue(`resaleCertificateList.${resaleCertList?.length}`, defaultResaleCertificateLine)

    }

    const deleteResaleCertificateLine = (index) => {
        const resaleCertList = getValues("resaleCertificateList");
        if (resaleCertList?.length) {
            let list = resaleCertList.filter((x: any, i: any) => {
                if (i !== index) {
                    return x
                }
            });
            if(list.length <= 1) {
                list = [ ...list, defaultResaleCertificateLine];
            }
            setValue("resaleCertificateList", list);
            trigger("resaleCertificateList");
        }
    }

    // console.log("check all resale certificate data ", getValues("resaleCertificateList.0"))
    const resaleCertificateListing = getValues("resaleCertificateList")?.map((resaleCertificate, index) => {
        return (
           <div key={index} className={styles.deleteCertLine}>

               <ResaleCertificateLineComponent 
                   index={index} 
                   resaleCertificate={resaleCertificate} 
                   deleteResaleCertificateLine={deleteResaleCertificateLine}
                   deleteCertificateTooltip={deleteCertificateTooltip}
                   openDeletePopup={openDeletePopup} 
                   register={register}
                   uploadCertFile={uploadCertFile}
                   States={States}
                   ResaleExpiration={ResaleExpiration}
                   control={control}
                   errors={errors}
       
       
               />
           </div>
        )
    });

    const exitImpersonation = async () => {
        setShowLoader(true);
        let payload = {
            data: {
                'email_id': userData.data.email_id
            }
        }
        if(channelWindow?.systemVersion){
            const systemVersion = window.electron.sendSync({ channel: channelWindow.systemVersion, data: null})
            payload.data.ui_version = import.meta.env.VITE_RENDERER_DEPLOY_VERSION;
            payload.data.os_version = systemVersion;
            payload.data.last_login_app_version = props.appVersion;
        }
        setShowLoader(true);
        navigate(routes.homePage);
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/logout', payload)
        .finally(async () => {
            props.partialLogout();
            resetCommonStore();
            props.resetImpersonate(originalLoggedInUserData.email_id);
        })
    }

    return (

        <>

            {/* <div className={clsx(styles.BuyerSetting, 'bgImg')}>
                <div className='headerPanel commonHeader'>
                    <Header />
                </div> */}

                <div className={clsx(styles.buyerSettingInnerContent, 'bgBlurContent', openChangePassPopup && styles.blurBg)}>
                    {/* {isDataLoad ?
                        <>
                            <div className='loaderMain'>
                                <Loader />
                            </div>
                        </>
                        : */}
                        <div className='scrollContent'>
                            <div className={styles.dFlex}>
                                <span className={styles.generalSettings}>General Settings</span>
                                {!!userData.data?.is_super_admin &&
                                    <span className={styles.impersonateIconStyle} onClick={() => {setShowLoader(true); navigate(routes.impersonateList)}}>Impersonate User</span>
                                }
                                {isImpersonatedUserLoggedIn &&
                                    <span className={styles.impersonateIconStyle} onClick={() => {exitImpersonation()}}>Exit Impersonation</span>
                                }
                                {(singleErrorKey && errors[singleErrorKey]?.message) &&
                                <div className={styles.errorMesDiv}>
                                    <span className={clsx(styles.errorMessage)}><ErrorMessageIcon /> {errors[singleErrorKey]?.message}</span>
                                </div>
                                }
                            </div>
                            <div className={styles.formInnerContent}>

                                <div className={clsx(styles.FormInputGroup, errors.companyName && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['companyName'])}>
                                    <span className={styles.lblInput}>
                                        Main Company
                                        <Tooltip
                                            title={BCompanyNameTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.comanyName, errors.companyName && styles.borderOfError)}>
                                    {isCompanyNameExists ?
                                        <input type='text' {...register("companyName")} className={styles.comanyNameInput1} placeholder='Enter company name' 
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("companyName").onBlur(e);
                                        }}
                                        readOnly />
                                        :
                                        <Controller
                                            name="companyName"
                                            control={control}
                                            render={({ field: { ...rest } }) => (
                                                <Autocomplete
                                                    value={companyNameValue}
                                                    onChange={(event, value) => {
                                                        setCompanyNameValue(value);
                                                        if(value?.company_name){
                                                            setDisableYourCompany(false)
                                                        }else{
                                                            setDisableYourCompany(true)
                                                            setYourCompanyList([])
                                                            setYourCompanyInput("")
                                                        }
                                                        rest.onChange(value?.company_name ?? null);
                                                    }}
                                                    inputValue={companyNameInput}
                                                    className={'companySelectDropdown'}
                                                    onInputChange={(event, newInputValue) => {
                                                        newInputValue = newInputValue.replace(emojiRemoverRegex, '');
                                                        setCompanyNameInput(newInputValue);
                                                        setValue("companyName", getValues("companyName"), { shouldDirty: true });
                                                    }}
                                                    classes={{
                                                        root: styles.autoCompleteDesc,
                                                        popper: styles.autocompleteDescPanel,
                                                        paper: styles.autocompleteDescInnerPanel,
                                                        listbox: styles.listAutoComletePanel,
                                                      }}
                                                    id="controllable-states-demo"
                                                    disablePortal={true}
                                                    options={companyListsData?.length ? companyListsData : []}
                                                    sx={{ width: '100%' }}
                                                    renderInput={(params) => <TextField className={styles.companyInput} {...params} placeholder="Select company name" />}
                                                    getOptionLabel={(item) => {
                                                        return item?.company_name ?? "";
                                                    }}
                                                />
                                                )}
                                            />}
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, errors.yourCompany && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['yourCompany'])}>
                                    <span className={styles.lblInput}>
                                        Your Company
                                        <Tooltip
                                            title={CompanyEntityTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.comanyName, errors.yourCompany && styles.borderOfError)}>
                                        <Controller
                                            name="yourCompany"
                                            control={control}
                                            render={({ field: { ...rest } }) => (
                                                <Autocomplete
                                                    freeSolo
                                                    disabled={disableYourCompany}
                                                    value={yourCompanyValue}
                                                    onChange={(event, value) => {
                                                        setYourCompanyValue(value);
                                                        rest.onChange(value ?? null);
                                                    }}
                                                    inputValue={yourCompanyInput}
                                                    className={'companySelectDropdown'}
                                                    onInputChange={(event, newInputValue) => {
                                                        newInputValue = newInputValue.replace(emojiRemoverRegex, '');
                                                        setYourCompanyInput(newInputValue);
                                                        rest.onChange(newInputValue)
                                                    }}
                                                    classes={{
                                                        root: styles.autoCompleteDesc,
                                                        popper: styles.autocompleteDescPanel,
                                                        paper: styles.autocompleteDescInnerPanel,
                                                        listbox: styles.listAutoComletePanel,
                                                        }}
                                                    id="controllable-states-demo"
                                                    disablePortal={true}
                                                    options={yourCompanyList?.length ? yourCompanyList : []}
                                                    sx={{ width: '100%' }}
                                                    renderInput={(params) => <TextField className={styles.companyInput} {...params} placeholder="Enter company name" />}
                                                    getOptionLabel={(item) => {
                                                        return item ?? "";
                                                    }}
                                                />
                                                )}
                                            />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.companyAddressLine || errors.companyState || errors.companyCity || errors.companyZipCode) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['companyAddressLine', 'companyState', 'companyCity', 'companyZipCode'])}>
                                    <span className={clsx(styles.lblInput, styles.lblAdress)}>
                                        Company Address
                                        <Tooltip
                                            title={BCompanyAddressTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.companyAddressLine && styles.borderOfError)}>
                                        <input type='text' {...register("companyAddressLine")} placeholder='Address' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("companyAddressLine").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("companyAddressLine").onBlur(e);
                                        }}/>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.cityInput, styles.bdrRadius0, styles.bdrRight0, errors.companyCity && styles.borderOfError)}>
                                        <input type='text' {...register("companyCity")} placeholder='City' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("companyCity").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("companyCity").onBlur(e);
                                        }}/>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.companyState, styles.bdrRadius0, styles.bdrRight0, errors.companyState && styles.borderOfError)}>
                                        <CustomMenu
                                            control={control}
                                            name={'companyState'}
                                            placeholder={'State'}
                                            MenuProps={{
                                                classes: {
                                                    paper: styles.Dropdownpaper,
                                                    list: styles.muiMenuList,
                                                },
                                            }}
                                            items={States.map(x => ({ title: x.code, value: x.id }))}
                                            className={'selectDropdown selectState'}

                                        />

                                    </span>
                                    <span className={clsx(styles.inputSection, styles.zipCodeInput, errors.companyZipCode && styles.borderOfError)}>
                                        <input type='tel' {...register("companyZipCode")} onChange={(e) => {
                                            register("companyZipCode").onChange(e)
                                            const zipCode = e.target.value.replace(/\D/g, '');
                                            setValue('companyZipCode', zipCode);
                                        }} maxLength="5" placeholder='Zip Code' />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.firstName || errors.lastName) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['firstName', 'lastName'])}>
                                    <span className={styles.lblInput}>
                                        Your Name
                                        <Tooltip
                                            title={BYourNameTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.firstName && styles.borderOfError)}>
                                        <input type='text' {...register("firstName")} placeholder='First name' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("firstName").onChange(e);  }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("firstName").onBlur(e);
                                        }}/>
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.lastName && styles.borderOfError)}>
                                        <input type='text' {...register("lastName")} placeholder='Last name' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("lastName").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("lastName").onBlur(e);
                                        }}/>
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.emailAddress || errors.phoneNo) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['emailAddress', 'phoneNo'])}>
                                    <span className={styles.lblInput}>
                                        Email / Phone
                                        <Tooltip
                                            title={BEmailPhoneTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.emailAddress && styles.borderOfError)}>
                                        <input type='text' {...register("emailAddress")} placeholder='Email address' onChange={handleEmailChange}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("emailAddress").onBlur(e);
                                        }}/>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.phoneNo, errors.phoneNo && styles.borderOfError)}>
                                        <input
                                            pattern="[0-9]"
                                            maxLength="14"
                                            type="tel"
                                            {...register("phoneNo")} onChange={(e) => {
                                                register("phoneNo").onChange(e)
                                                handlePhoneNoChange(e)
                                            }}
                                            placeholder='(xxx) xxx-xxxx'
                                        />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, errors.yourCompany && styles.FormInputGroupError)}>
                                    <span className={styles.lblInput}>
                                      Password
                                        <Tooltip
                                            title={'Change Password'}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.comanyName, errors.yourCompany && styles.borderOfError)}>
                                       <span onClick={changePassPopup}  className={styles.changePassBtn}>Change Password</span>
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, (errors.deliverToAddress || errors.deliverToCity || errors.deliverToState || errors.deliverZipCode) && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['deliverToAddress', 'deliverToCity', 'deliverToState', 'deliverZipCode'])}>
                                    <span className={clsx(styles.lblInput, styles.lblAdress)}>
                                        Deliver To
                                        <Tooltip
                                            title={BDeliverToTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.deliverToAddress && styles.borderOfError)}>
                                        <input type='text' {...register("deliverToAddress")} placeholder='Address' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("deliverToAddress").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("deliverToAddress").onBlur(e);
                                        }}/>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.cityInput, styles.bdrRadius0, styles.bdrRight0, errors.deliverToCity && styles.borderOfError)}>
                                        <input type='text' {...register("deliverToCity")} placeholder='City' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("deliverToCity").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("deliverToCity").onBlur(e);
                                        }}/>
                                    </span>
                                    <span className={clsx(styles.inputSection,styles.companyState, styles.bdrRadius0, styles.bdrRight0, errors.deliverToState && styles.borderOfError)}>
                                        <CustomMenu
                                            control={control}
                                            name={'deliverToState'}
                                            placeholder={'State'}
                                            MenuProps={{
                                                classes: {
                                                    paper: styles.Dropdownpaper,
                                                    list: styles.muiMenuList,
                                                },
                                            }}
                                            items={States.map(x => ({ title: x.code, value: x.id }))}
                                            className={'selectDropdown selectState'}

                                        />

                                    </span>
                                    <span className={clsx(styles.inputSection, styles.zipCodeInput, errors.deliverZipCode && styles.borderOfError)}>
                                        <input {...register("deliverZipCode")} type='tel' onChange={(e) => {
                                            register("deliverZipCode").onChange(e)
                                            const zipCode = e.target.value.replace(/\D/g, '');
                                            setValue('deliverZipCode', zipCode);
                                        }} maxLength="5" placeholder='Zip Code' />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, errors.deliveryDate && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['deliveryDate'])}>
                                    <span className={styles.lblInput}>
                                        Delivery Date
                                        <Tooltip
                                            title={BDeliverDateTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, styles.paddingLR0, errors.deliveryDate && styles.borderOfError)}>
                                        <CustomMenu
                                            control={control}
                                            name={'deliveryDate'}
                                            placeholder={'Choose One'}
                                            MenuProps={{
                                                classes: {
                                                    paper: clsx(styles.Dropdownpaper,styles.DropdownDeliveryDate),
                                                    list: styles.muiMenuList,
                                                },
                                            }}
                                            items={DeliveryDates.map(x => ({ title: x.delivery_date_string, value: x.days_to_add }))}
                                            className={'selectDropdown SelectDeliveryDate'}
                                            category={'Choose One'}

                                        />
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, errors.sendInvoicesTo && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['sendInvoicesTo'])}>
                                    <span className={styles.lblInput}>
                                        Send Invoices to
                                        <Tooltip
                                            title={BSendInvoicesTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.sendInvoicesTo && styles.borderOfError)}>
                                        <input {...register("sendInvoicesTo")} type='text' placeholder='Enter AP email address (multiple separate with a comma)' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("sendInvoicesTo").onChange(e); }} 
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("sendInvoicesTo").onBlur(e);
                                        }}/>
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, errors.shippingDocsTo && styles.FormInputGroupError)}
                                    onClick={() => showErrorKeyVal(['shippingDocsTo'])}>
                                    <span className={styles.lblInput}>
                                        Shipping Docs to
                                        <Tooltip
                                            title={BShippingDocsTooltip()}
                                            arrow
                                            placement={'right'}
                                            disableInteractive
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 200 }}
                                            classes={{
                                                tooltip: 'inputTooltip',
                                            }}
                                        >
                                            <span className={styles.questionIcon}>
                                                <QuestionIcon className={styles.questionIcon1} />
                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                            </span>

                                        </Tooltip>
                                    </span>
                                    <span className={clsx(styles.inputSection, errors.shippingDocsTo && styles.borderOfError)}>
                                        <input type='text' {...register("shippingDocsTo")} placeholder='Enter email address (multiple separate with a comma)' 
                                        onChange={(e) => { e.target.value = e.target.value.replace(emojiRemoverRegex, ''); register("shippingDocsTo").onChange(e); }}
                                        onBlur={(e) => {
                                            e.target.value = e.target.value.trim();
                                            register("shippingDocsTo").onBlur(e);
                                        }}/>
                                    </span>
                                </div>

                                <div className={clsx(styles.FormInputGroup, styles.resaleCertificateSection, (errors.resaleCertState1 || errors.resaleCertState2 || errors.resaleCertExpire1 || errors.resaleCertExpire2) && styles.FormInputGroupError)} onClick={() => showErrorKeyVal(['resaleCertState1', 'resaleCertState2', 'resaleCertExpire1', 'resaleCertExpire2'])}>
                                    <div className='w100'>
                                        <div className='dflex'>
                                            <span className={clsx(styles.lblInput, styles.resaleFirstTopDiv)}>
                                                <span className={styles.resaleCertTitle}>
                                                    Resale Cert
                                                    <Tooltip
                                                        title={BResaleCertTooltip()}
                                                        arrow
                                                        placement={'right'}
                                                        disableInteractive
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{ timeout: 200 }}
                                                        classes={{
                                                            tooltip: 'inputTooltip',
                                                        }}
                                                    >
                                                        <span className={styles.questionIcon}>
                                                            <QuestionIcon className={styles.questionIcon1} />
                                                            <QuestionHoverIcon className={styles.questionIcon2} />
                                                        </span>

                                                    </Tooltip>
                                                </span>
                                                <span>
                                                  <button onClick={()=>{handleAddNewLine()}} className={styles.addCertificateBtn}><AddCertiIcon/>Add State</button>
                                                </span>
                                            </span>
                                            <span className={clsx(styles.inputSection2, styles.resaleMiddleTopDiv)}>
                                                <div className={styles.UploadCerTitle}>Upload Cert</div>
                                            </span>

                                            <span className={clsx(styles.inputSection3, styles.resaleMiddleTopDiv)}>
                                                <div className={styles.UploadCerTitle}>State</div>
                                            </span>
                                            <span className={clsx(styles.inputSection, styles.inputSection4, styles.resaleMiddleTopDiv)}>
                                                <div className={styles.UploadCerTitle}>Expiration</div>
                                            </span>
                                            <span className={clsx(styles.inputSection, styles.inputSection5, styles.resaleLastTopDiv)}>
                                                <div className={styles.UploadCerTitle}>Status</div>
                                            </span>
                                        </div>
                                        { resaleCertificateListing }
                                    </div>
                                </div>

                                <div className={clsx(styles.FormInputGroup, styles.recevingHours)}>
                                    <span className={styles.lblInput}>
                                        <span className={styles.resaleCertTitle}>
                                            Receiving Hours
                                            <Tooltip
                                                title={BReceivingHoursTooltip()}
                                                arrow
                                                placement={'right'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'inputTooltip',
                                                }}
                                            >
                                                <span className={styles.questionIcon}><QuestionIcon /></span>

                                            </Tooltip>
                                        </span>
                                        <span className={styles.lblReceivingHours}>First Truck In</span>
                                        <span className={styles.lblReceivingHours}>Last Truck In</span>
                                    </span>
                                    {watch('dates')?.map((x, i) => (<span key={x.day} className={styles.inputSectionRecevingHours}>
                                                {/* <input type='hidden' value={x.day} {...register(`dates.${x.value}.name`)} /> */}
                                                {/* <input id={x.display_name} {...register(`dates.${i}.is_user_available`)} type='checkbox' />
                                                <label htmlFor={x.display_name} className={styles.daylbl1}>{x.display_name}</label> */}
                                                <span className={`${watch(`dates.${i}.from`)!=='closed' ? styles.daylbl1 : ''}`}>{x.display_name}</span>
                                                <span className={clsx(styles.daylbl2, 'w100 dflex')}>
                                                    <CustomMenu
                                                        control={control}
                                                        defaultValue={x.from}
                                                        name={`dates.${i}.from`}
                                                        className={'selectReceivingHours selectUploadCertDropdown'}
                                                        MenuProps={MenuPropsTop}
                                                        items={x.receivingHrsFrom}
                                                        onChange={(events) => {
                                                            changeReceivingHrs(i, true, events.target.value);
                                                        }}
                                                    />
                                                </span>
                                                <span className={clsx(styles.daylbl3, 'w100 dflex')}>
                                                    <CustomMenu
                                                        defaultValue={x.to}
                                                        control={control}
                                                        name={`dates.${i}.to`}
                                                        className={'selectReceivingHours selectUploadCertDropdown'}
                                                        MenuProps={MenuPropsBottom}
                                                        items={x.receivingHrsTo}
                                                        onChange={(events) => {
                                                            changeReceivingHrs(i, false, events.target.value);
                                                        }}
                                                    />
                                                </span>
                                            </span>))}

                                </div>

                                <span className={styles.generalSettings}>Payment Settings</span>

                                {isApproved === null ?
                                    // {/* Net 30 Pending State Section */}
                                    <div className={clsx(styles.FormInputGroup, styles.netTerms)}>
                                        <span className={styles.lblInput} >
                                            <span className={styles.netTermsChk}>
                                                <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                    <input type='checkbox' {...register('net30CheckBox')} value={net30Default} checked={selectedPaymentMethod === net30Default} onChange={(e) => { register("net30CheckBox").onChange(e); handlePaymentMethodChange(net30Default) }} />
                                                    <span>Net 30 Terms</span>
                                                    <span className={clsx(styles.checkmark, 'checkmark')} />
                                                </label>
                                                <Tooltip
                                                    title={BNet30TermsTooltip()}
                                                    arrow
                                                    placement={'right'}
                                                    disableInteractive
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 200 }}
                                                    classes={{
                                                        tooltip: 'inputTooltip',
                                                    }}
                                                >
                                                    <span className={styles.questionIcon}>
                                                        <QuestionIcon className={styles.questionIcon1} />
                                                        <QuestionHoverIcon className={styles.questionIcon2} />
                                                    </span>

                                                </Tooltip>
                                            </span>
                                        </span>
                                        <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                            <input type='text' placeholder='' readOnly value={`Requested ${getValues('requestedCreditLimit') || formatCurrency(parseFloat(getValues('creditLine')))}`} />
                                        </span>
                                        <span className={clsx(styles.inputSection, styles.btnApplyMain, styles.pendingBtn)}>
                                            <span className={styles.applyBtn} >Pending</span>
                                        </span>
                                    </div>
                                    : creditStatus === null && isApproved === 1 ?
                                        //  {/* Net 30 Approved State Section */}

                                        <div className={clsx(styles.FormInputGroup, styles.achCredit, styles.netPaymentApporvedSection)}>
                                            <span className={styles.lblInput}>
                                                <span className={styles.netTermsChk}>
                                                    <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                        <input type='checkbox' {...register('net30CheckBox')} value={net30Default}
                                                            checked={selectedPaymentMethod === net30Default}
                                                            onChange={(e) => { register("net30CheckBox").onChange(e); handlePaymentMethodChange(net30Default) }}
                                                        />
                                                        <span className={clsx(styles.checkmark, 'checkmark')} />
                                                    </label>
                                                    <span>Net 30 Terms</span>
                                                    <Tooltip
                                                        title={BNet30TermsTooltip()}
                                                        arrow
                                                        placement={'right'}
                                                        disableInteractive
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{ timeout: 200 }}
                                                        classes={{
                                                            tooltip: 'inputTooltip',
                                                        }}
                                                    >
                                                        <span className={styles.questionIcon}>
                                                            <QuestionIcon className={styles.questionIcon1} />
                                                            <QuestionHoverIcon className={styles.questionIcon2} />
                                                        </span>

                                                    </Tooltip>
                                                </span>
                                                {RequestcreditLineAmount ? (
                                                    <div>
                                                        <input type="string" value={formatCurrencyWithComma(watch('requestCreditLine'))} onChange={(e) => requestCreditLineChangeHandler(e, "requestCreditLine")} className={clsx(styles.requestCreditLineInput, errors.requestCreditLine && styles.borderOfError)} placeholder='Enter Amount' />
                                                        <button onClick={(e) => { setRequestcreditLineAmount(false); setValue('requestCreditLine',''); clearErrors('requestCreditLine') }} className={styles.cancelBtn}>Cancel</button>
                                                        <button onClick={submitRequestCreditLine} className={clsx(styles.submitBtn)} disabled={disableRequestCreditLine()}>Submit</button>
                                                    </div>
                                                ) : (
                                                    <button className={styles.requestcreditlineIncreaseText} onClick={(e) => setRequestcreditLineAmount(true)}>
                                                        Request A Credit Line Increase
                                                    </button>
                                                )
                                                }
                                            </span>
                                            <div className={styles.netPaymentApporved}>
                                                <div className={styles.netPaymentApporvedCol}>
                                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                        <div>D&B Number</div>
                                                        <div>{getValues('dnBNumber')}</div>
                                                    </span>
                                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                        <div>EIN</div>
                                                        <div>{getValues('einNumber')}</div>
                                                    </span>
                                                    <span className={styles.inputSection}>
                                                        <div>Credit Status</div>
                                                        <div>Approved</div>
                                                    </span>
                                                </div>

                                                <div className={styles.netPaymentApporvedCol}>
                                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                        <div>Credit Line</div>
                                                        <div>{getValues('balanceCreditLimit')}</div>
                                                    </span>
                                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                        <div>Outstanding</div>
                                                        <div>{getValues('outstandingAmount')}</div>
                                                    </span>
                                                    <span className={styles.inputSection}>
                                                        <div>Available</div>
                                                        <div>{getValues('availableBalance')}</div>
                                                    </span>
                                                </div>

                                            </div>

                                        </div>
                                        : creditStatus === 'Pending Increase' && isApproved === 1 ?

                                            //  {/* Net 30 Pending Increase State Section */}
                                            <div className={clsx(styles.FormInputGroup, styles.achCredit, styles.netPaymentApporvedSection)}>
                                                <span className={styles.lblInput}>
                                                    <span className={styles.netTermsChk}>
                                                        <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                            <input type='checkbox' {...register('net30CheckBox')} value={net30Default} checked={selectedPaymentMethod === net30Default} onChange={(e) => { register("net30CheckBox").onChange(e); handlePaymentMethodChange(net30Default) }} />
                                                            <span>Net 30 Terms</span>
                                                            <span className={clsx(styles.checkmark, 'checkmark')} />
                                                        </label>

                                                        <Tooltip
                                                            title={BNet30TermsTooltip()}
                                                            arrow
                                                            placement={'right'}
                                                            disableInteractive
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 200 }}
                                                            classes={{
                                                                tooltip: 'inputTooltip',
                                                            }}
                                                        >
                                                            <span className={styles.questionIcon}>
                                                                <QuestionIcon className={styles.questionIcon1} />
                                                                <QuestionHoverIcon className={styles.questionIcon2} />
                                                            </span>

                                                        </Tooltip>
                                                    </span>
                                                    {/* {RequestcreditLineAmount ? (
                                  <div>
                                      <input type="number" className={styles.requestCreditLineInput} placeholder='Enter Amount' />
                                      <button onClick={(e) => setRequestcreditLineAmount(false)} className={styles.cancelBtn}>Cancel</button>
                                      <button onClick={(e) => setRequestcreditLineAmount(false)} className={styles.submitBtn}>Submit</button>
                                  </div>
                                  ) :  (                            */}
                                                    <button className={styles.requestcreditlineIncreaseText} onClick={handleCreditReauqestCancel} >
                                                        Cancel Request
                                                    </button>
                                                    {/* )
                              } */}
                                                </span>
                                                <div className={styles.netPaymentApporved}>
                                                    <div className={styles.netPaymentApporvedCol}>
                                                        <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                            <div>D&B Number</div>
                                                            <div>{getValues('dnBNumber')}</div>
                                                        </span>
                                                        <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                            <div>EIN</div>
                                                            <div>{getValues('einNumber')}</div>
                                                        </span>
                                                        <span className={styles.inputSection}>
                                                            <div>Credit Status</div>
                                                            <div>{creditStatus}</div>
                                                        </span>
                                                    </div>

                                                    <div className={styles.netPaymentApporvedCol}>
                                                        <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                            <div>Credit Line</div>
                                                            <div>{getValues('requestedIncreaseCredit')}  <span className={styles.pendingStatusNetTerms} >(Pending)</span></div>
                                                        </span>
                                                        <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                            <div>Outstanding</div>
                                                            <div>{getValues('outstandingAmount')}</div>
                                                        </span>
                                                        <span className={styles.inputSection}>
                                                            <div>Available</div>
                                                            <div>{getValues('availableBalance')}</div>
                                                        </span>
                                                    </div>

                                                </div>
                                                <span className={styles.requestReceived}>
                                                    <CheckReceived /> <span className={styles.requestReceivedtext}> Request received</span>
                                                </span>
                                            </div>
                                            : (creditStatus === 'Approved' || creditStatus === 'Cancelled') && isApproved === 1 ?

                                                //  {/* Net 30 Approved State Section */}

                                                <div className={clsx(styles.FormInputGroup, styles.achCredit, styles.netPaymentApporvedSection)}>
                                                    <span className={styles.lblInput}>
                                                        <span className={styles.netTermsChk}>
                                                            <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                                <input type='checkbox' {...register('net30CheckBox')} value={net30Default} checked={selectedPaymentMethod === net30Default} onChange={(e) => { register("net30CheckBox").onChange(e); handlePaymentMethodChange(net30Default) }} />
                                                                <span>Net 30 Terms</span>
                                                                <span className={clsx(styles.checkmark, 'checkmark')} />
                                                            </label>
                                                            <Tooltip
                                                                title={BNet30TermsTooltip()}
                                                                arrow
                                                                placement={'right'}
                                                                disableInteractive
                                                                TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 200 }}
                                                                classes={{
                                                                    tooltip: 'inputTooltip',
                                                                }}
                                                            >
                                                                <span className={styles.questionIcon}>
                                                                    <QuestionIcon className={styles.questionIcon1} />
                                                                    <QuestionHoverIcon className={styles.questionIcon2} />
                                                                </span>

                                                            </Tooltip>
                                                        </span>

                                                        {RequestcreditLineAmount ? (
                                                            <div>
                                                                <input type="string" value={formatCurrencyWithComma(watch('requestCreditLine'))} onChange={(e) => requestCreditLineChangeHandler(e, "requestCreditLine")} className={styles.requestCreditLineInput} placeholder='Enter Amount' />
                                                                <button onClick={(e) => { setRequestcreditLineAmount(false); setValue('requestCreditLine',''); clearErrors(); }} className={styles.cancelBtn}>Cancel</button>
                                                                <button onClick={submitRequestCreditLine} className={styles.submitBtn}  disabled={disableRequestCreditLine()}>Submit</button>
                                                            </div>
                                                        ) : (
                                                            <button className={styles.requestcreditlineIncreaseText} onClick={(e) => setRequestcreditLineAmount(true)}>
                                                                Request A Credit Line Increase
                                                            </button>
                                                        )
                                                        }
                                                    </span>
                                                    <div className={styles.netPaymentApporved}>
                                                        <div className={styles.netPaymentApporvedCol}>
                                                            <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                                <div>D&B Number</div>
                                                                <div>{getValues('dnBNumber')}</div>
                                                            </span>
                                                            <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                                <div>EIN</div>
                                                                <div>{getValues('einNumber')}</div>
                                                            </span>
                                                            <span className={styles.inputSection}>
                                                                <div>Credit Status</div>
                                                                <div>Approved</div>
                                                            </span>
                                                        </div>
                                                        <div className={styles.netPaymentApporvedCol}>
                                                            <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                                <div>Credit Line</div>
                                                                <div>{getValues('balanceCreditLimit')}</div>
                                                            </span>
                                                            <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0)}>
                                                                <div>Outstanding</div>
                                                                <div>{getValues('outstandingAmount')}</div>
                                                            </span>
                                                            <span className={styles.inputSection}>
                                                                <div>Available</div>
                                                                <div>{getValues('availableBalance')}</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className={clsx(styles.FormInputGroup, styles.netTerms, (errors.dnBNumber || errors.einNumber || errors.creditLine) && styles.FormInputGroupError)}
                                                    onClick={() => showErrorKeyVal(['dnBNumber', 'einNumber', 'creditLine'])}>
                                                    <span className={styles.lblInput}>
                                                        <span className={styles.netTermsChk}>
                                                            <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                                <input type='checkbox' {...register("net30CheckBox")} value={net30Default} checked={selectedPaymentMethod === net30Default} onChange={(e) => { register("net30CheckBox").onChange(e); handlePaymentMethodChange(net30Default) }} />
                                                                <span>Net 30 Terms</span>
                                                                <span className={clsx(styles.checkmark, 'checkmark')} />
                                                            </label>
                                                            <Tooltip
                                                                title={BNet30TermsTooltip()}
                                                                arrow
                                                                placement={'right'}
                                                                disableInteractive
                                                                TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 200 }}
                                                                classes={{
                                                                    tooltip: 'inputTooltip',
                                                                }}
                                                            >
                                                                <span className={styles.questionIcon}>
                                                                    <QuestionIcon className={styles.questionIcon1} />
                                                                    <QuestionHoverIcon className={styles.questionIcon2} />
                                                                </span>
                                                            </Tooltip>
                                                        </span>
                                                    </span>
                                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.dnBNumber && styles.borderOfError)}>
                                                        <input type='text' {...register("dnBNumber")} pattern="[0-9]" maxLength="9" onChange={(e) => {
                                                            register("dnBNumber").onChange(e)
                                                            const dnb = e.target.value.replace(/\D/g, '');
                                                            setValue('dnBNumber', dnb);
                                                            // handleDnbNoChange(e)

                                                        }} placeholder='D&B Number' 
                                                        onBlur={(e) => {
                                                            e.target.value = e.target.value.trim();
                                                            register("dnBNumber").onBlur(e);
                                                        }}/>
                                                    </span>
                                                    <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.einNumber && styles.borderOfError)}>
                                                        <input type='text' maxLength={10} {...register("einNumber")} onChange={(e) => {
                                                            register("einNumber").onChange(e)
                                                            handleEinNoChange(e)
                                                        }} placeholder='EIN' 
                                                        onBlur={(e) => {
                                                            e.target.value = e.target.value.trim();
                                                            register("einNumber").onBlur(e);
                                                        }}/>
                                                    </span>

                                                    <span
                                                        className={clsx(
                                                            styles.inputSection,
                                                            styles.bdrRadius0,
                                                            styles.bdrRight0,
                                                            errors.creditLine && styles.borderOfError
                                                        )}
                                                    >
                                                        {editModeDesiredCreditLine ? (
                                                            <ClickAwayListener
                                                            onClickAway={() => desiredCreditLineEditModeHandler(false)}
                                                              >
                                                                <div className={styles.desiredcreditLineInput}>
                                                                <input
                                                                    id='desired-credit-line'
                                                                    type="string"
                                                                    value={
                                                                        (+watch("creditLine")) ? 
                                                                        (formatCurrencyWithComma(watch("creditLine")) ?? "") : 
                                                                        (watch("creditLine") ?? "")
                                                                    }
                                                                    onChange={(e) => {
                                                                        requestCreditLineChangeHandler(e, "creditLine")
                                                                    }}
                                                                    placeholder="Desired Credit Line"
                                                                />
                                                                </div>
                                                               
                                                            </ClickAwayListener>
                                                        ) : (
                                                            <span className={styles.desiredcreditLine} onClick={() => desiredCreditLineEditModeHandler(true)}>
                                                                $ {formatToTwoDecimalPlaces(watch("creditLine"))}
                                                            </span>
                                                        )}
                                                    </span>
                                                    <span className={clsx(styles.inputSection, styles.btnApplyMain)}>
                                                        <button className={styles.applyBtn} onClick={(event) => handleSubmit(submitNetData)()}>Apply</button>
                                                        <SubmitApplicationSuccessDialog />
                                                    </span>
                                                </div>

                                }

                                {achApproved === null ?

                                    <div className={clsx(styles.FormInputGroup, styles.achCredit, (errors.bankName || errors.accountNo || errors.routingNo) && styles.FormInputGroupError)}
                                        onClick={() => showErrorKeyVal(['bankName', 'accountNo', 'routingNo'])} >
                                        <span className={styles.lblInput}>
                                            <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                <input type='checkbox' {...register('achCheckBox')} value={achDefault} checked={selectedPaymentMethod === achDefault} onChange={(e) => {
                                                    register("achCheckBox").onChange(e)
                                                    handlePaymentMethodChange(achDefault)
                                                }} />
                                                <span>Cash In Advance</span>
                                                <span className={clsx(styles.checkmark, 'checkmark')} />
                                            </label>
                                            <Tooltip
                                                title={BACHCreditTooltip()}
                                                arrow
                                                placement={'right'}
                                                disableInteractive
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 200 }}
                                                classes={{
                                                    tooltip: 'inputTooltip',
                                                }}
                                            >
                                                <span className={styles.questionIcon}>
                                                    <QuestionIcon className={styles.questionIcon1} />
                                                    <QuestionHoverIcon className={styles.questionIcon2} />
                                                </span>

                                            </Tooltip>
                                        </span>
                                        <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.bankName && styles.borderOfError)}>
                                            <label>Bank Name</label>
                                            <span className={styles.textHoverMask}>
                                                <span>{getValues('bankName')}</span>
                                            </span>
                                        </span>
                                        <span className={clsx(styles.inputSection, styles.accountNoPending, styles.bdrRadius0, styles.bdrRight0, errors.routingNo && styles.borderOfError)}>
                                            <label>Routing No.</label>
                                            <span className={styles.textHoverMask} onMouseEnter={handleMouseEnterRout} onMouseLeave={handleMouseLeaveRout}>
                                                <span>{isRoutingMasked ? maskedRoutingNo : getValues('refRoutingNo') ?? routingNumber}</span>
                                            </span>
                                        </span>
                                        <span className={clsx(styles.inputSection, styles.accountNoPending, styles.bdrRadius0, styles.bdrRight0, errors.accountNo && styles.borderOfError)}>
                                            <label>Account No.</label>
                                            <span className={styles.textHoverMask} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                <span>{isAccountMasked ? maskedAccountNo : getValues('refAccountNo') ?? accountNumber}</span>
                                            </span>
                                        </span>
                                        <span className={clsx(styles.inputSection, styles.btnApplyMain, styles.pendingBtn)}>
                                            <button className={styles.applyBtn} >Pending</button>
                                        </span>
                                    </div>
                                    :

                                    achApproved === 1 ?

                                        <div className={clsx(styles.FormInputGroup, styles.achCredit, (errors.bankName || errors.accountNo || errors.routingNo) && styles.FormInputGroupError)}
                                            onClick={() => showErrorKeyVal(['bankName', 'accountNo', 'routingNo'])} >
                                            <span className={styles.lblInput}>
                                                <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                    <input type='checkbox' {...register('achCheckBox')} value={achDefault} checked={selectedPaymentMethod === achDefault} onChange={(e) => {
                                                        register("achCheckBox").onChange(e)
                                                        handlePaymentMethodChange(achDefault)
                                                    }} />
                                                    <span>Cash In Advance</span>
                                                    <span className={clsx(styles.checkmark, 'checkmark')} />
                                                </label>
                                                <Tooltip
                                                    title={BACHCreditTooltip()}
                                                    arrow
                                                    placement={'right'}
                                                    disableInteractive
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 200 }}
                                                    classes={{
                                                        tooltip: 'inputTooltip',
                                                    }}
                                                >
                                                    <span className={styles.questionIcon}>
                                                        <QuestionIcon className={styles.questionIcon1} />
                                                        <QuestionHoverIcon className={styles.questionIcon2} />
                                                    </span>

                                                </Tooltip>
                                            </span>
                                            <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.bankName && styles.borderOfError)}>
                                                <label>Bank Name</label>
                                                <span className={styles.textHoverMask}>
                                                    <span>{getValues('bankName')}</span>
                                                </span>
                                            </span>
                                            <span className={clsx(styles.inputSection, styles.accountNo, styles.bdrRadius0, styles.bdrRight0, errors.routingNo && styles.borderOfError)}>
                                                <label>Routing No.</label>
                                                <span className={styles.textHoverMask} onMouseEnter={handleMouseEnterRout} onMouseLeave={handleMouseLeaveRout}>
                                                    <span>{isRoutingMasked ? maskedRoutingNo : getValues('refRoutingNo') ?? routingNumber}</span>
                                                </span>

                                            </span>
                                            <span className={clsx(styles.inputSection, styles.accountNo, errors.accountNo && styles.borderOfError)}>
                                                <label>Account No.</label>
                                                <span className={styles.textHoverMask} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <span>{isAccountMasked ? maskedAccountNo : getValues('refAccountNo') ?? accountNumber}</span>
                                                </span>

                                            </span>
                                        </div>
                                        :

                                        <div className={clsx(styles.FormInputGroup, styles.achCredit)}
                                        >
                                            <span className={styles.lblInput}>
                                                <label className={clsx(styles.lblCheckbox, 'containerChk')}>
                                                    <input type='checkbox' {...register('achCheckBox')} value={achDefault} checked={selectedPaymentMethod === achDefault} onChange={(e) => {
                                                        register("achCheckBox").onChange(e)
                                                        handlePaymentMethodChange(achDefault)
                                                    }} />
                                                    <span>Cash In Advance</span>
                                                    <span className={clsx(styles.checkmark, 'checkmark')} />
                                                </label>
                                                <Tooltip
                                                    title={BACHCreditTooltip()}
                                                    arrow
                                                    placement={'right'}
                                                    disableInteractive
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 200 }}
                                                    classes={{
                                                        tooltip: 'inputTooltip',
                                                    }}
                                                >
                                                    <span className={styles.questionIcon}>
                                                        <QuestionIcon className={styles.questionIcon1} />
                                                        <QuestionHoverIcon className={styles.questionIcon2} />
                                                    </span>

                                                </Tooltip>
                                            </span>
                                            <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.bankName && styles.borderOfError)}>
                                                <label>Bank Name</label>
                                                <span className={styles.textHoverMask}>
                                                    <span>{getValues('bankName')}</span>
                                                </span>
                                            </span>
                                            <span className={clsx(styles.inputSection, styles.bdrRadius0, styles.bdrRight0, errors.routingNo && styles.borderOfError)}>
                                                <label>Routing No.</label>
                                                <span className={styles.textHoverMask}>
                                                    <span>{getValues('routingNo')}</span>
                                                </span>

                                            </span>
                                            <span className={clsx(styles.inputSection, styles.accountNo, errors.accountNo && styles.borderOfError)}>
                                                <label>Account No.</label>
                                                <span className={styles.textHoverMask}>
                                                    <span>{getValues('accountNo')}</span>
                                                </span>
                                            </span>
                                            <span className={styles.achSetupBlank}>Please contact Bryzos team to apply for Cash In Advance.</span>
                                            <span className={styles.achSetupBlankOverlay}></span>
                                        </div>

                                }


                                <div className={styles.myReportsTitle}>My Reports <span>(click report name to export)</span></div>
                                <CommonTooltip
                                    title={"Here to can view your previous orders that you have placed"}
                                    tooltiplabel={<button className={styles.purchaseOrderHistoryText} onClick={() => { downloadReports(import.meta.env.VITE_API_SERVICE + `/user/purchaseOrderExcel`, 'Puchase Order History', fileType.excelSheet) }}>Purchase Order History</button>}
                                    placement={'right'}
                                    classes={{
                                        popper: 'tooltipPopper',
                                        tooltip: 'tooltipMain2 tooltipMain6',
                                        arrow: 'tooltipLeftArrow1'
                                    }}
                                    localStorageKey="purchaseHistoryTooltip"
                                />
                                <button className={clsx(styles.purchaseOrderHistoryText, styles.dFlex)} onClick={() => { downloadReports(import.meta.env.VITE_API_SERVICE + `/user/payableStatementExcel`, 'Payment History', fileType.excelSheet) }}>Payment History (Accounts Payable Statement)</button>
                            </div>
                        </div>
                    {/* } */}

                    <div className={styles.btnSection}>
                        <div>
                            <button onClick={() => isDirty ? setBackToHome(true) : navigate(backNavigation)} className={styles.backBtn}>
                                <span>Back</span>
                            </button>
                        </div>
                        <div className={styles.termsAndPatent}>
                            <div className={clsx(styles.TermsandConditions, socket?.connected && styles.version)}>{props.appVersion}</div>
                            <button className={styles.TermsandConditions} onClick={() => navigate(routes.TnCPage, { state: { isViewMode: true, navigateTo: routes.buyerSettingPage } })}>Terms and Conditions</button>
                            <div className={styles.patentPendingText}>Patent Pending</div>
                        </div>
                        <div className={styles.btnRight}>
                            <button onClick={handleFormSubmit} disabled={isSaveDisable} className={clsx(styles.backBtn, styles.saveBtn)}>Save</button>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            <>
                <Dialog
                    open={apiFailureDialog}
                    onClose={(event) => setApiFailureDialog(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: styles.ErrorDialog,
                        paper: styles.dialogContent
                    }}

                >
                    <p>"Something went wrong. Please try again in sometime"</p>
                    <button className={styles.submitBtn} onClick={(event) => setApiFailureDialog(false)}>Ok</button>
                </Dialog>
            </>
            <>
                <Dialog
                    open={openErrorDialog}
                    onClose={(event) => setOpenErrorDialog(false)}
                    transitionDuration={200}
                    hideBackdrop
                    classes={{
                        root: styles.ErrorDialog,
                        paper: styles.dialogContent
                    }}

                >
                    <p>No data found. Please try again in sometime</p>
                    <button className={styles.submitBtn} onClick={(event) => setOpenErrorDialog(false)}>Ok</button>
                </Dialog>
            </>
            <DialogPopup
            dialogTitle={dialogTitle}
            dialogContent={dialogContent}
            dialogBtnTitle={dialogBtnTitle}
            type={dialogType}
            open={showDialogUploadCertPopup}
            onClose={() => setShowDialogUploadCertPopup(false)}
            />

        <Dialog 
                open={openDeleteConfirmation}
                transitionDuration={200}
                hideBackdrop
                classes={{
                    root: styles.ErrorDialog,
                    paper: styles.dialogContent
                }}
        >
            <div>
            <p>Are you sure you want to delete the file?</p>
            <div className={styles.deleteBtnSection}>
                <button className={styles.submitBtn} onClick={deleteCerti}>Yes</button>
                <button className={styles.submitBtn} onClick={() => setOpenDeleteConfirmation(false)}>No</button>
            </div>
            </div>
      </Dialog>

            <Dialog
                    open={openChangePassPopup}
                    onClose={(event) => setOpenChangePassPopup(false)}
                    transitionDuration={100}
                    hideBackdrop
                    classes={{
                        root: styles.changePassDialog,
                        paper: styles.dialogContent
                    }}
                >
                    <button className={styles.closeIcon} onClick={(event) => setOpenChangePassPopup(false)}><CloseIcon /></button>
                <ChangePassword
                closeDialog={()=>{setOpenChangePassPopup(false)}}
                getDeviceId={props.getDeviceId}/>
            </Dialog>

            <BackToHomeDialog />
        </>

    );
}

export default BuyerSetting;

export const ResaleCertificateLineComponent = ({index, resaleCertificate, deleteResaleCertificateLine, deleteCertificateTooltip, openDeletePopup, register, uploadCertFile, States, ResaleExpiration, control, errors}) => {
    const resaleCertFileRef = useRef();
    const resaleCertEditHandler = () => {
        resaleCertFileRef.current.click();
    }
    return(
        <div className={clsx('dflex', !!!resaleCertificate.is_deletable && styles.disabledCert)} key={index}>
            <span className={clsx(styles.lblInput, styles.resaleFirstBottomDiv,styles.delIconCertif)}>
                {index > 1 && !resaleCertificate.id &&
                        <Tooltip
                        title={deleteCertificateLineTooltip()}
                        arrow
                        placement={'right'}
                        disableInteractive
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 200 }}
                        classes={{
                            tooltip: 'inputTooltip',
                        }}
                    >
                    <button onClick={() => deleteResaleCertificateLine(index)} className={clsx(styles.deleteCertBtn,styles.deleteCertLineBtn)}><DeleteCertiIcon /></button>
                    </Tooltip>
                }
                <span className={clsx(styles.state1, index > 1 && !resaleCertificate.id ? '' : styles.state1AddLine)}>State {index + 1}
                    {resaleCertificate?.cerificate_url_s3 ?
                        <>
                            <a href={resaleCertificate?.cerificate_url_s3} className={styles.viewCert}>(View Cert)</a>
                            {resaleCertificate?.id && !!resaleCertificate.is_deletable &&
                                <Tooltip
                                    title={deleteCertificateTooltip()}
                                    arrow
                                    placement={'right'}
                                    disableInteractive
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 200 }}
                                    classes={{
                                        tooltip: 'inputTooltip',
                                    }}
                                >
                                    <button onClick={() => openDeletePopup(resaleCertificate?.id, index)} className={styles.deleteCertBtn}><DeleteIcon /></button>
                                </Tooltip>
                            }

                        </>
                        : ''}
                </span>
            </span>
            <span className={clsx(styles.inputSection2, styles.resaleMiddleBottomDiv)}>
                {resaleCertificate?.uploadCertProgress === true ?
                    <label className='dflex'>
                        <span className={styles.uploadText}>Uploading</span>
                    </label>
                    : resaleCertificate?.uploadCertProgress === false ?
                        <label className='dflex'>
                            <button className={styles.uploadText} onClick={resaleCertEditHandler}>
                                Upload / Replace </button>
                        </label>
                        :
                        <label className='dflex'>
                            <button className={styles.uploadText} onClick={resaleCertEditHandler}>Upload</button>

                        </label>

                }
                <input {...register(`resaleCertificateList.${index}.resaleCertFile`)} type='file' onChange={(e) => {uploadCertFile(e, index); register(`resaleCertificateList.${index}.resaleCertFile`).onChange(e)  }} ref={(e) => { resaleCertFileRef.current = e; register(`resaleCertificateList.${index}.resaleCertFile`).ref(e); }} />
            </span>

            <span className={clsx(styles.inputSection3, styles.resaleMiddleBottomDiv)}>

                <span className='w100 dflex'>
                    <CustomMenu
                        control={control}
                        name={`resaleCertificateList.${index}.state_id`}
                        placeholder={'Select'}
                        MenuProps={{
                            classes: {
                                paper: clsx(styles.DropdownUploadCertpaper, styles.DropdownUploadCertpaperBottom),
                                list: styles.muiMenuList,
                            },
                            anchorOrigin: {
                                vertical: 27,
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                        }}
                        items={States.map(x => ({ title: x.code, value: x.id }))}
                        className={clsx('selectUploadCertDropdown', errors?.resaleCertificateList?.[index]?.state_id && styles.borderOfError)}

                    />
                </span>

            </span>
            <span className={clsx(styles.inputSection, styles.inputSection4, styles.resaleMiddleBottomDiv)}>
                <span className={styles.inputSectionDate}>
                    <CustomMenu
                        control={control}
                        name={`resaleCertificateList.${index}.expiration_date`}
                        placeholder={'Select'}
                        MenuProps={ExpirationMenuPropsBottom}
                        items={ResaleExpiration}
                        className={clsx('selectUploadCertDropdown',errors?.resaleCertificateList?.[index]?.expiration_date && styles.borderOfError)}
                    />
                </span>
            </span>
            <span className={clsx(styles.inputSection, styles.inputSection5, styles.resaleLastBottomDiv)}>

                <div className={styles.UploadCertStatus}>
                    {resaleCertificate?.status && resaleCertificate.status}

                </div>


            </span>
        </div>
    )
}
