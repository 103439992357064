// @ts-nocheck
import React, { useEffect, useState } from 'react';
import styles from './orderConfirmation.module.scss'
import { ReactComponent as QuestionIcon } from '../../assets/images/question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../assets/images/question-white-hover.svg';
import { commomKeys, fileType, orderConfirmationConst, prefixUrl, routes } from '../../../common';
import { useLocation, useNavigate } from 'react-router';
import { Dialog, Fade, Tooltip } from '@mui/material';
import { SUploadYourSOTooltip } from '../../tooltip';
import clsx from 'clsx';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { downloadFiles, navigatePage } from '../../helper';
import DialogPopup from '../../component/DialogPopup/DialogPopup';
import { useGlobalStore } from '@bryzos/giss-ui-library';


const OrderConfirmationSeller = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData, setShowLoader } = useGlobalStore();
    const soData = props;
    const [rating, setRating] = useState(0);
    const [disableUploadYourSo, setDisableUploadYourSo] = useState(false);
    const [internalPoNumber, setInternalPoNumber] = useState();
    const [resaleCertificateUrls, setResaleCertificateUrls] = useState([]);
    const [showDialogConfirmationPopup, setShowDialogConfirmationPopup] = useState(false);
    const [uploadSoProgress, setUploadSoProgress] = useState(null);
    //Dialog Popup 
    const [showDialogUploadSoPopup, setShowDialogUploadSoPopup] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [dialogBtnTitle, setDialogBtnTitle] = useState('');
    const [dialogType, setDialogType] = useState('');
    const [cancelOrderDisableBtn, setCancelOrderDisableBtn] = useState(false);
    //

    useEffect(()=>{
        axios.get(import.meta.env.VITE_API_SERVICE + '/user/getResaleListOfBuyer/'+soData.poNumber )
        .then(res => {
            setInternalPoNumber(res.data.data.buyer_internal_po);
            if(res.data.data.resale_certificate_url?.length > 0)
            setResaleCertificateUrls(res.data.data.resale_certificate_url);
        })
        .catch(err => console.error(err));
    },[])

    useEffect(() => {
        function goToMainPage(event) {
            if (event.key === 'Enter') {
                navigate(routes.homePage);

            }
        }

        document.addEventListener('keydown', goToMainPage)
        return (() => {
            document.removeEventListener('keydown', goToMainPage)
        })
    }, [navigate])



    const handleCheckboxClick = (value) => {
        setRating(value);
        const soRatingPayload = {
            "data": {
                "po_number": soData.poNumber,
                "rating": value.toString()
            }
        }
        axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/salesOrderRatings',soRatingPayload)
        .then(res => {})
        .catch(err => console.error(err))
    };


    const uploadSoFile = async (event) => {
        const file = event.target.files[0];

        if (event.target.files.length !== 0) {
            // setUploadCertName1(file.name);
            setDisableUploadYourSo(true);
            setUploadSoProgress(true);
            let index = file.name.length - 1;
            for (; index >= 0; index--) {
                if (file.name.charAt(index) === '.') {
                    break;
                }
            }
            const ext = file.name.substring(index + 1, file.name.length);

            const objectKey = import.meta.env.VITE_ENVIRONMENT + '/' + userData.data.id + '/' + prefixUrl.sellerSo + '-' + uuidv4() + '.' + ext;
            const payload = {
                data: {
                    "bucket_name": import.meta.env.VITE_S3_UPLOAD_SETTINGS_SO_BUCKET_NAME,
                    "object_key": objectKey,
                    "expire_time": 300

                }
            }
            let sellerSoUrl = 'https://' + payload.data.bucket_name + ".s3.amazonaws.com/" + payload.data.object_key;
            
            axios.post(import.meta.env.VITE_API_SERVICE + '/user/get_signed_url', payload)
                .then(response => {
                    const signedUrl = response.data.data;
                    axios.put(signedUrl, file)
                        .then(response => {
                            if (response.status === 200) {
                                const payload = {
                                    "data": {
                                        "po_number": soData.poNumber,
                                        "upload_po_s3_urls": [
                                            sellerSoUrl
                                        ]
                                    }
                                }
                                axios.post(import.meta.env.VITE_API_SERVICE + '/user/save/uploadSoS3Url', payload)
                                    .then(response => {
                                        setShowDialogUploadSoPopup(true);
                                        setDialogTitle(commomKeys.uploadSuccessful)
                                        setDialogContent(orderConfirmationConst.uploadSoDialogContent)
                                        setDialogBtnTitle(commomKeys.successBtnTitle)
                                        setDialogType(commomKeys.actionStatus.success)
                                        setUploadSoProgress(false);
                                    })
                                    .catch(err => {
                                        setDisableUploadYourSo(false);
                                        setUploadSoProgress(null);
                                        setShowDialogUploadSoPopup(true);
                                        setDialogContent(commomKeys.errorContent)
                                        setDialogBtnTitle(commomKeys.errorBtnTitle)
                                        setDialogType(commomKeys.actionStatus.error)
                                        console.error(err)
                                    })

                            }
                        })
                        .catch(error => {
                            setDisableUploadYourSo(false);
                            setUploadSoProgress(null);
                            setShowDialogUploadSoPopup(true);
                            setDialogContent(commomKeys.errorContent)
                            setDialogBtnTitle(commomKeys.errorBtnTitle)
                            setDialogType(commomKeys.actionStatus.error)
                            console.error(error)
                        })
                })
                .catch(error => {
                    setDisableUploadYourSo(false);
                    setUploadSoProgress(null);
                    setShowDialogUploadSoPopup(true);
                    setDialogContent(commomKeys.errorContent)
                    setDialogBtnTitle(commomKeys.errorBtnTitle)
                    setDialogType(commomKeys.actionStatus.error)
                    console.error(error)
                })

        }
    }

    const downloadCertificate = async()=>{
        try{
            setShowLoader(true)
            const res = await axios.get(import.meta.env.VITE_API_SERVICE + '/user/bryzosStatesResaleCertUrl')
            if(res.data.data){
                const url = res.data.data
                const fileName = url.split('/')
                await downloadFiles(url, fileName[fileName.length -1], fileType.pdf)
            }
        }catch(e) {
            setShowDialogUploadSoPopup(true);
            setDialogContent(commomKeys.errorContent)
            setDialogBtnTitle(commomKeys.errorBtnTitle)
            setDialogType(commomKeys.actionStatus.error)
        } finally {
            setShowLoader(false);
        }
    }

    const cancelOrder = () => {
        setCancelOrderDisableBtn(true)
        const payload = {
            data:{
                po_number: soData.poNumber,
                type: orderConfirmationConst.sellerCancel
            }
        }
        axios.post(import.meta.env.VITE_API_ADMIN_SERVICE_NODE+'/cancel_order/user',payload)
            .then(res => {
                navigate(-1);
                setDisableUploadYourSo(false);
                setUploadSoProgress(null);
                setCancelOrderDisableBtn(false)
            })
        .catch(err => {console.error(err); setCancelOrderDisableBtn(false)});
    };

    return (
        <div>
             {/* <div className={clsx(styles.BuyerSetting, 'bgImg')}>
                <div className='headerPanel commonHeader'>
                    <Header />
                </div> */}
                <div className={clsx(styles.buyerorderConfirmationContent, 'bgBlurContent')}>
                    <span className={styles.orderConfirmation}>Congrats on the New Order !!</span>
                    <div className={styles.emailHeder} >
                        <span className={styles.orderEmail}>We have just emailed the order </span>
                        <br />
                        <span className={styles.orderEmail}> confirmation to <span className={styles.emailIdInvoice}>{userData.data.email_id}</span>.</span>
                    </div>
                    <div className={styles.poBoxOne}>
                        <div className={styles.poNumberCreator}>{soData.poNumber}<span className={styles.poNumber}>(PO# {internalPoNumber})</span></div>
                        
                    </div>
                    <div className={styles.uploadYourPo1}>
                        <label>
                            <div className={clsx(styles.uploadYourPoDiv, (disableUploadYourSo) && styles.disabled)}>
                                {uploadSoProgress === true ?
                                    <span className={styles.poNumber}>Uploading </span> :
                                    uploadSoProgress === false ?
                                        <span className={styles.poNumber}>SO Uploaded </span> :
                                        <>
                                            <span className={styles.poNumber}>Upload Your SO</span>
                                            <span className={styles.emailIdInter}>or, email to ack@bryzos.com</span>
                                            <input type='file' onChange={uploadSoFile}  disabled={disableUploadYourSo} />
                                        </>
                                }
                            </div>
                        </label>

                        <Tooltip
                            title={SUploadYourSOTooltip()}
                            arrow
                            placement={'left'}
                            disableInteractive
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 200 }}
                            classes={{
                                tooltip: 'inputTooltip',
                            }}
                        >
                            <div className={styles.svgDiv}>
                                <QuestionIcon className={styles.questionIcon1} />
                                <QuestionHoverIcon className={styles.questionIcon2} />
                            </div>

                        </Tooltip>
                    </div>
                    <div className={clsx(styles.uploadYourPo, styles.pointer)} >
                        <span className={clsx(styles.poNumber)} >Download Confirmation &</span>
                            <span className={clsx(styles.poNumber)} onClick={downloadCertificate}>
                            Sales Tax Exemption Certificate</span>
                    </div>
                    <div onClick={() => { navigatePage(location.pathname, {path: routes.orderPage})}} className={clsx(styles.uploadYourPo, styles.pointer)}>
                        <span className={styles.poNumber}>Claim Another Order</span>
                    </div>
                    <div onClick={() => { setShowDialogConfirmationPopup(true) }} className={clsx(styles.uploadYourPo, styles.pointer)}>
                        <span className={styles.poNumber}>Cancel Order</span>
                    </div>
                    <div className={styles.setRatingBox} >
                        {/* <span><Staroutlinedicon /><Staroutlinedicon /><Staroutlinedicon /><Staroutlinedicon /><Staroutlinedicon /></span> */}
                        <div className="star-rating">
                            <input type="checkbox" id="star1" name="rating" value="1" checked={rating >= 1}
                                onChange={() => handleCheckboxClick(1)} />
                            <label htmlFor="star1"></label>
                            <input type="checkbox" id="star2" name="rating" value="2" checked={rating >= 2}
                                onChange={() => handleCheckboxClick(2)} />
                            <label htmlFor="star2"></label>
                            <input type="checkbox" id="star3" name="rating" value="3" checked={rating >= 3}
                                onChange={() => handleCheckboxClick(3)} />
                            <label htmlFor="star3"></label>
                            <input type="checkbox" id="star4" name="rating" value="4" checked={rating >= 4}
                                onChange={() => handleCheckboxClick(4)} />
                            <label htmlFor="star4"></label>
                            <input type="checkbox" id="star5" name="rating" value="5" checked={rating >= 5}
                                onChange={() => handleCheckboxClick(5)} />
                            <label htmlFor="star5"></label>
                        </div>

                        <span className={styles.emailIdInter} >Rate Your Selling Experience!</span>
                    </div>
                <div onClick={() => { navigate(routes.homePage); setDisableUploadYourSo(false);}} className={styles.returnInstantPricing}>Return to Instant Pricing</div>
                    {/* <span> <ReturntoSearchIcon /></span> */}
                </div>
            {/* </div> */}
            <Dialog
                open={showDialogConfirmationPopup}
                onClose={(event) => (false)}
                transitionDuration={200}
                hideBackdrop
                classes={{
                    root: styles.ConfirmationDialog,
                    paper: styles.dialogContent
                }}
            >
                <p>Do you want to continue?</p>
                <div className={styles.actionsTab}>
                    <button className={styles.submitBtn} onClick={(event) => cancelOrder()} disabled={cancelOrderDisableBtn}>Yes</button>
                    <button className={styles.cancelBtn} onClick={(event) => setShowDialogConfirmationPopup(false)}>No</button>
                </div>
            </Dialog>
            <DialogPopup
            dialogTitle={dialogTitle}
            dialogContent={dialogContent}
            dialogBtnTitle={dialogBtnTitle}
            type={dialogType}
            open={showDialogUploadSoPopup}
            onClose={() => setShowDialogUploadSoPopup(false)}
            />
        </div>
    )
}

export default OrderConfirmationSeller;